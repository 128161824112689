

















































































































































































































































































import { errorNotification, successNotification } from '@/includes/NotificationService'
import TableUserActionButtons from '@/components/TableUserActionButtons.vue'
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import RanksHandlerModule from "@/includes/logic/Modules/modules/modules/RanksHandlerModule/RanksHandlerModule";
import StatisticsApi from "@/includes/Api/Statistics.api";
import {
  ReputationStatistics,
  UserReputation
} from "@/views/chat/statistics/logic/usersStatTypes";
import PiTableHeader from "@/components/chat/PiTableHeader.vue";
import StaticDataApi from "@/includes/Api/StaticData.api";

import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import UserMainInfo from "piramis-base-components/src/components/UserMainInfo.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";

import { Component, Mixins } from 'vue-property-decorator'
import moment from 'moment'
import { atSignedLogin } from "piramis-base-components/src/shared/utils/tgLoginHelpers";

type TableColumns = 'reputation' | 'rank' | 'xp' | 'ap'

@Component({
  methods: {
    moment,
  },
  'components': {
    PiTableHeader,
    TableUserActionButtons,
    TableExportButtons,
    Accordion,
    Icon,
    UserMainInfo,
    NumberInput,
  },
})
export default class VuReputation extends Mixins(ModelSetter) {
  isChangeKarmaModalOpen = false

  activeUserRecord: UserReputation | null = null

  karmaValue = 0

  reputationStatistics: ReputationStatistics | null = null

  get isXpSystemEnabled() {
    return this.reputationStatistics?.user_ranks_by_xp ?? false
  }

  get isReputationSystemEnabled() {
    return this.reputationStatistics?.user_ranks_by_reputation ?? false
  }

  search = ''

  columnsVisibility: Record<TableColumns, boolean> = {
    reputation: true,
    rank: true,
    xp: true,
    ap: true
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        key: 'index',
        slots: { title: 'customIndex' },
        scopedSlots: { customRender: 'index' },
      },
      {
        key: 'name',
        slots: { title: 'userInfo' },
        scopedSlots: { customRender: 'userInfo' },
      },
      ...this.columnsVisibility.reputation ? [ {
        key: 'karma',
        slots: { title: 'karma' },
        scopedSlots: { customRender: 'karma' },
        sorter: (a:UserReputation, b:UserReputation) =>  (a.karma - a.anti_karma) - (b.karma - b.anti_karma)
      } ] : [],
      ...this.columnsVisibility.rank ? [ {
        dataIndex: 'rank_title',
        key: 'rank_title',
        slots: { title: 'customRankTitle' },
        scopedSlots: { customRender: 'rank_title' },
        sorter: (a:UserReputation, b:UserReputation) =>  (a?.rank ?? 0) - (b?.rank ?? 0),
      } ] : [],
      ...this.columnsVisibility.xp ? [ {
        dataIndex: 'xp',
        key: 'xp',
        slots: { title: 'customXp' },
        scopedSlots: { customRender: 'xp' },
        sorter: (a:UserReputation, b:UserReputation) =>  (a?.xp ?? 0) - (b?.xp ?? 0),
      } ] : [],
      ...this.columnsVisibility.ap ? [ {
        dataIndex: 'action_point',
        key: 'action_point',
        slots: { title: 'customActionPoint' },
        scopedSlots: { customRender: 'action_points' },
        sorter: (a:UserReputation, b:UserReputation) =>  (a?.action_point ?? 0) - (b?.action_point ?? 0),
      } ] : [],
    ]
  }

  get searchResult(): Array<UserReputation> {
    if (this.reputationStatistics) {
      if (!this.search.length) {
        return this.reputationStatistics.users
      } else {
        return this.reputationStatistics.users
          .filter(user => {
            const trimmedQuery = this.search.trim().toLowerCase()

            return user.login && atSignedLogin(user.login).toLowerCase().includes(atSignedLogin(trimmedQuery))
              || user.name && user.name.toLowerCase().includes(trimmedQuery)
              || user.id && (user.id.toString().includes(trimmedQuery))
          })
      }
    }

    return []
  }

  get exportTable(): Array<Record<string, number | string>> {
    if (this.reputationStatistics) {
      return this.reputationStatistics.users.map(user => {
        return {
          [this.$t('reputation_export_table_user_index').toString()]: user.index,
          [this.$t('reputation_export_table_user_id').toString()]: user.id,
          [this.$t('reputation_export_table_user_name').toString()]: user.name ? user.name : '-',
          [this.$t('reputation_export_table_user_login').toString()]: user.login ? user.login : '-',
          [this.$t('reputation_export_table_user_karma').toString()]: user.karma ? user.karma : '-',
          [this.$t('reputation_export_table_user_anti_karma').toString()]: user.anti_karma ? user.anti_karma : '-',
          [this.$t('reputation_export_table_user_rank_title').toString()]: user.rank_title ? user.rank_title : '-',
          [this.$t('reputation_export_table_user_rank_index').toString()]: user.rank_title ? this.getRankIndex(user.rank_title) : '-',
          [this.$t('reputation_export_table_user_xp').toString()]: user.xp ? user.xp : '-',
          [this.$t('reputation_export_table_user_action_points').toString()]: typeof user.action_point === 'number' ? user.action_point : '-',
        }
      })
    }

    return []
  }

  get changeKarmaModalTitle() {
    if (this.activeUserRecord) {
      return this.$t('set_reputation_user_title', [ this.activeUserRecord.name ])
    }
  }

  prepareRecordForEdit(record: UserReputation) {
    this.activeUserRecord = record
    this.karmaValue = record.karma - record.anti_karma
    this.isChangeKarmaModalOpen = true
  }

  submitKarmaValue() {
    if (this.activeUserRecord) {
      StatisticsApi.setReputation('tg', {
        chat_id: this.$store.getters.chatId,
        user_id: this.activeUserRecord.id,
        value: this.karmaValue
      })
        .then(({ data }) => {
          if (data) {
            successNotification()

            this.$store.commit('setObjectField', {
              object: this.$store.state.chatState.chat,
              objectPath: "stat_access_key",
              value: data.stat_access_key,
            })

            const u = this.reputationStatistics?.users?.find(u => u.id === this.activeUserRecord?.id)

            if (u) {
              u.karma = data.karma
              u.anti_karma = data.anti_karma
            }

            this.isChangeKarmaModalOpen = false
          }
        })
        .catch(errorNotification)
    }
  }

  getRankIndex(title: string): number {
    const ranksModule = ModuleManagerState?.modules?.find(m => m instanceof RanksHandlerModule)

    if (ranksModule) {
      return (ranksModule as RanksHandlerModule).params.user_ranks.findIndex((rank) => rank.title === title) + 1
    } else {
      return -1
    }
  }

  countDifference(currIndex: number, prevIndex: number): string {
    if (currIndex < prevIndex) {
      return `+${ prevIndex - currIndex }`
    } else {
      return `-${ currIndex - prevIndex }`
    }
  }

  checkColumnsVisibility(statistics: ReputationStatistics) {
    const fieldExists = (record: UserReputation, field: keyof UserReputation) => typeof record[field] === 'number' && record[field] !== 0

    this.columnsVisibility.reputation = statistics.users.some(r => fieldExists(r, 'karma') || fieldExists(r, 'anti_karma'))
    this.columnsVisibility.rank = statistics.users.some(r => fieldExists(r, 'rank'))
    this.columnsVisibility.xp = statistics.users.some(r => fieldExists(r, 'xp'))
    this.columnsVisibility.ap = statistics.users.some(r => fieldExists(r, 'action_point'))

    const keys = Object.keys(this.columnsVisibility)

    if (keys.every(k => this.columnsVisibility[k as TableColumns])) {
      keys.forEach((key) => {
        this.columnsVisibility[key as TableColumns] = true
      })
    }
  }

  async loadReputationData(): Promise<void> {
    const statAccessKey = this.$store.state.chatState.chat?.stat_access_key

    try {
      this.$baseTemplate.loader.open();
      const { data } = await StaticDataApi.getReputationStat(statAccessKey)

      this.reputationStatistics = data

      this.checkColumnsVisibility(data)
    } catch (error) {
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }

  created(): void {
    this.loadReputationData()
  }
}
